<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách dự toán chi phí làm hàng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary md-search">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái duyệt]</label>
                                <label v-else for="status">Trạng thái duyệt</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="0">Lưu tạm</md-option>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="3">Không duyệt</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo mã lô hàng</label>
                                <md-input v-model="search.orderCode"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số bill/booking</label>
                                <md-input v-model="search.noBooking"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo mã dự toán</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày lập</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày lập</label>
                            </md-datepicker>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-fixed" style="height: calc(-326px + 100vh); overflow-x: auto; width: 100%;">
                        <table class="data-table" style="width: 2000px;">
                            <thead>
                                <tr>
                                    <th style="width:100px;">#</th>
                                    <th style="width:6%;">Hành động</th>
                                    <th style="width:120px;">Trạng thái</th>
                                    <th style="width:10%;">Loại dự toán</th>                                
                                    <th style="width:10%;">Đơn hàng</th>
                                    <th style="width:10%;">Khách hàng</th>
                                    <th style="width:10%;">Số bill/booking</th>
                                    <th style="width:10%;">Mã dự toán</th>
                                    <th class="right" style="width:10%; padding-right: 10px;">Số tiền</th>
                                    <th style="width:8%;">Người dự toán</th>                                
                                    <th style="width:6%;">Ngày lập</th>
                                    <th style="width:8%;">Tạm ứng</th>
                                    <th style="width:8%;">Giải chi</th>
                                    <th style="width:8%;">Quyết toán</th>                                
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td v-if="isReport == false" class="action">
                                        <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                            <i class="uil uil-eye"></i>
                                            <md-tooltip>Xem</md-tooltip>
                                        </md-button>
                                        <md-button v-if="item.isAdvancePayment || item.isInvoice" disabled class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                        <md-button v-else @click="edit(item.id)" class="md-fab md-mini md-second">
                                            <md-icon>edit</md-icon>
                                            <md-tooltip>Sửa</md-tooltip>
                                        </md-button>
                                    </td>
                                    <td v-else class="action">
                                        <md-button @click="view(item.id)" class="md-fab md-mini md-view">
                                            <i class="uil uil-eye"></i>
                                            <md-tooltip>Xem</md-tooltip>
                                        </md-button>
                                    </td>
                                    <td class="center">
                                        <md-chip v-if="item.status == 0" class="status-2 status-temp">Lưu tạm</md-chip>
                                        <md-chip v-if="item.status == 1" class="status-2 status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                        <md-chip v-else-if="item.status == 2" class="status-2 status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                        <md-chip v-else-if="item.status == 3" class="status-2 status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                        <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    </td>
                                    <td class="center">
                                        <md-chip style="width: 150px;" class="md-primary">{{ getPaymentType(item.paymentType) }}</md-chip>
                                    </td>                                
                                    <td class="left">{{item.order.orderCode}}</td>
                                    <td class="left">{{item.order.customer.companyAlias}}</td>
                                    <td class="left">{{item.order.booking.noBooking}}</td>
                                    <td class="left">{{item.estimateCode}}</td>
                                    <td class="right">{{formatNumber(item.pay)}}</td>
                                    <td class="left">{{item.staff.fullName}}</td>                                
                                    <td class="center">{{item.estimateDate}}</td>
                                    <td class="center">
                                        <md-chip  v-if="item.isAdvancePayment" class="md-primary">Đã tạm ứng</md-chip>
                                        <md-chip v-else>Chưa tạm ứng</md-chip>
                                    </td>
                                    <td class="center">
                                        <md-chip  v-if="item.isExplain" class="md-primary">Đã giải chi</md-chip>
                                        <md-chip v-else>Chưa giải chi</md-chip>
                                    </td>
                                    <td class="center">
                                        <md-chip  v-if="item.isInvoice" class="md-primary">Đã quyết toán</md-chip>
                                        <md-chip v-else>Chưa quyết toán</md-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-footer" v-if="pager.totalPages > 1">
                        <div class="grid">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <div class="move-page">
                                        <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                        <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                    </div>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div class="paging">
                                        <div class="total-page">
                                            <div>Page</div> 
                                            <div style="padding-left:10px;">
                                                <md-field>
                                                    <md-select v-model="search.pageIndex">
                                                        <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                    </md-select>
                                                </md-field>
                                            </div>
                                            <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                        </div>
                                        <div class="row-of-page">
                                            <div style="padding-right:10px;">Rows/page:</div>
                                            <md-field>
                                                <md-select v-model="search.pageSize">
                                                    <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div class="record-of-page">
                                            <div style="padding-right:10px;">Xem:</div>
                                            <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="move-page">
                                        <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                        <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <orderList ref="orderList" @close="closeOrder"/>
    </div>
</template>
<script>
    import orderEstimateService from '@/api/orderEstimateService';
    import orderService from '@/api/orderService';
    import companyService from '@/api/companyService';
    import messageBox from '@/utils/messageBox';
    import common from '@/mixins';
    import { mapActions } from 'vuex';
    import customerList from '@/components/popup/_CompanyList.vue';
    import orderList from '@/components/popup/_OrderList.vue';
    import { APP_CONFIG } from '@/utils/constants'

    export default {
        components: {
            customerList,
            orderList
        },
        metaInfo: {
            title: 'Danh sách dự toán chi phí làm hàng'
        },
        data() {
            return {
                loadding: false,
                search: { pageIndex: 1, pageSize: common.pageSize, status: '', customerId: 0, noBooking: '', orderCode: '', code: '', fromDate: '', toDate: '' },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                id: 0,
                status: [],
                selectedAllStatus: false,
                orderType: 1,
                isReport: false,
                customers: [],
                customerName: '',
                orderName: '',
                orders: [],
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.name === 'report-order-estimate'){
                this.isReport = true;
            }
            else{
                this.isReport = false;
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/store-receipt/export-excel-receipt?status=" + this.search.status +
                "&code=" + this.search.code + "&supplierId=" + this.search.supplierId + "&receiverId=" + this.search.receiverId +
                "&delivererId=" + this.search.delivererId + "&fromDate=" + this.search.fromDate + "&toDate=" + this.search.toDate;
                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            btnSearch(){
                this.filter();
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 100, companyType: common.companyType.customer, alias: searchTerm };
                companyService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            openCustomer(){
                this.$refs.customerList.open(7);
            },

            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
            },

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['0','1','2','3'];
                }
                else{
                    this.status = [];
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search = { pageIndex: 1, pageSize: common.pageSize, status: '', customerId: 0, noBooking: '', orderCode: '', code: '', fromDate: '', toDate: '' };
                this.getData();
            },

            filter(){
                this.getData();
            },

            getData(){
                this.loadding = true;
                this.search.orderType = this.orderType;
                orderEstimateService.filter(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            add(){
                this.$router.push("/order-estimate/crud?orderType=" + this.orderType);
            },
            
            edit(id){
                this.$router.push('/order-estimate/crud/' + id);
            },

            view(id){
                this.$router.push('/order-estimate/view/' + id);
            }
        },
        watch: {
            '$route.name': function (){
                if(this.$route.name === 'report-order-estimate'){
                    this.isReport = true;
                }
                else{
                    this.isReport = false;
                }
            },
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 4){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
            },
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.fromDate': function (val) {
                if(val == null){
                    this.search.fromDate = "";
                }
            },
        }
    }

</script>
